import { Menu, Row, Col, Progress } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import * as lineupActions from '../../actions/lineup'
import * as afterStartActions from '../../actions/after-start'

import Players from './components/Players'
// import Settings from './components/Settings'
const Settings = React.lazy(() => import('./components/Settings'))
const TeamStacks = React.lazy(() => import('./components/TeamStacks'))
const Lineups = React.lazy(() => import('./components/Lineups'))
const Groups = React.lazy(() => import('../lineup-generator/components/Groups'))
const AfterStart = React.lazy(() => import('./components/AfterStart'))
const Admin = React.lazy(() => import('../lineup-generator/Admin'))
// import TeamStacks from './components/TeamStacks'
// import Lineups from './components/Lineups'
// import Groups from './components/Groups'
// import AfterStart from './components/AfterStart'

import { allowCheatsheets, customPresets } from '../../constants/partner'
import OptimizerTopSection from '../../components/OptimizerTopSection'
import { StyledMenu } from '../../components/shared-styles'

class PositionalOptimizer extends Component {
  state = {
    afterStartChecked: false
  }

  validateBuild = () => {
    const { players, showdown } = this.props
    if (!players.data || players.data.error) return false

    let valid = true
    const errors = []

    if (!showdown) {
      players.data.forEach(_player => {
        // if any player has a greater MinExp than MaxExp not valid
        if (Number(_player.MinExp) > Number(_player.MaxExp)) {
          errors.push(
            `${_player.Name} has a greater Min Exp than Max Exp`
          )
          valid = false
        }
      })
    }

    // make sure sport is allowed
    try {
      let sportAllowed = this.props.subscription.permissions.opt.nfl.lineup_limit > 0
      if (!sportAllowed) {
        throw Error('not allowed')
      }
    } catch(e) {
      valid = false
      errors.push("Not authorized for this sport")
    }
    
    return {
      valid,
      errors
    }
  }

  render() {
    const {
      opt,
      players,
      settings,
      site,
      slate,
      tab,
      updateSlate,
      clearStackSettings,
      clearPlayerData,
      zeroExposures,
      showdown
    } = this.props

    let body
    const sport = this.props.sport
    switch (tab) {
      case 'players':
        body = (
          <Players
            players={players}
            settings={settings}
            clearPlayerData={clearPlayerData.bind(this)}
            zeroExposures={zeroExposures.bind(this)}
            updateSlate={updateSlate.bind(this)}
            slate={slate}
            site={site}
            showdown={showdown}
            counter={this.props.counter}
            season={this.props.season}
            changeDateInfo={this.props.changeDateInfo}
            updateSite={this.props.updateSite}
            sport={sport}
          />
        )
        break
      case 'settings':
        body = (
          <Settings
            settings={settings}
            updateSlate={updateSlate.bind(this)}
            clearStackSettings={clearStackSettings.bind(this)}
            slate={slate}
            site={site}
            players={players}
            showdown={showdown}
            counter={this.props.counter}
            season={this.props.season}
            changeDateInfo={this.props.changeDateInfo}
            sport={sport}
          />
        )
        break
      case 'team-stacks':
        body = (
          <TeamStacks
            settings={settings}
            updateSlate={updateSlate.bind(this)}
            clearStackSettings={clearStackSettings.bind(this)}
            slate={slate}
            site={site}
            showdown={showdown}
            counter={this.props.counter}
            season={this.props.season}
            changeDateInfo={this.props.changeDateInfo}
            sport={sport}
          />
        )
        break
      case 'groups':
        body = (
          <Groups
            players={players}
            updateSlate={updateSlate.bind(this)}
            slate={slate}
            site={site}
            showdown={showdown}
            counter={this.props.counter}
            season={this.props.season}
            changeDateInfo={this.props.changeDateInfo}
            sport={sport}
          />
        )
        break
      case 'lineups':
        body = (
          <Lineups
            opt={opt}
            numLUs={settings.data.numLUs}
            site={site}
            slate={slate}
            showdown={showdown}
            counter={this.props.counter}
            season={this.props.season}
            changeDateInfo={this.props.changeDateInfo}
            sport={sport}
          />
        )
        break
      case 'after-start':
        body = (
          <AfterStart
            site={site}
            slate={slate}
          />
        )
        break
      case 'admin':
        body = (
          <Admin
            site={site}
            slate={slate}
            counter={this.props.counter}
            season={this.props.season}
            sport={this.props.sport}
            players={players}
            allowCustomPresets={customPresets}
            allowCheatsheets={allowCheatsheets}
          />
        )
        break
      default:
        body = false
    }

    const { valid, errors } = this.validateBuild()

    return (
      <div>
        <OptimizerTopSection
          sport={this.props.sport}
          site={site}
          slate={slate}
          counter={this.props.counter}
          season={this.props.season}
          updateSlate={updateSlate.bind(this)}
          changeDateInfo={this.props.changeDateInfo}
          updateSite={this.props.updateSite}
          loading={this.props.opt.loading}
          cancelOpt={this.props.cancelOpt}
          getLineups={this.props.getLineups}
          valid={valid}
          errors={errors}
          handbuildMode={this.props.handbuildMode.active}
        />
        <Row>
          <Col lg={16} md={24} sm={24} xs={24} >
            <StyledMenu onClick={(e) => this.props.changeTab(e.key)} selectedKeys={[this.props.tab]} mode="horizontal">
              {
                showdown ? (
                  <>
                    <Menu.Item key={'players'}>Players</Menu.Item>
                    <Menu.Item key={'settings'}
                      disabled={
                          this.props.players.loading || this.props.settings.loading
                      }
                    >
                      Build Settings
                    </Menu.Item>
                    <Menu.Item key={'team-stacks'} disabled={
                        this.props.players.loading || this.props.settings.loading
                    }>
                      Team Stacks
                    </Menu.Item>
                    <Menu.Item key={'groups'} disabled={
                        this.props.players.loading || this.props.settings.loading
                    }>
                     Player Groups
                    </Menu.Item>
                    <Menu.Item
                      key={'lineups'}
                    >
                      Lineups
                      {
                        this.props.opt.loading ? (
                          <Progress
                            style={{marginLeft: '5px'}}
                            percent={(Number(this.props.opt.data ? this.props.opt.data.length / 9 : 0)/Number(this.props.settings.data.numLUs)*100).toFixed(0)}
                            steps={5}
                            size="small"
                            strokeColor="#1890ff"
                            showInfo={false}
                          />
                        ) : ''
                      }
                    </Menu.Item>
                    {
                      this.props.auth.admin ? (
                        <Menu.Item 
                          key={'admin'}
                        >
                          Admin
                        </Menu.Item>
                        ) : ''
                    } 
                  </>
                ) : (
                  <>
                    <Menu.Item key={'players'}>Players</Menu.Item>
                    <Menu.Item key={'settings'}
                      disabled={
                          this.props.players.loading || this.props.settings.loading
                      }
                    >
                      Build Settings
                    </Menu.Item>
                    <Menu.Item key={'team-stacks'} disabled={
                        this.props.players.loading || this.props.settings.loading
                    }>
                      Team Stacks
                    </Menu.Item>
                    <Menu.Item key={'groups'} disabled={
                        this.props.players.loading || this.props.settings.loading
                    }>
                      Player Groups
                    </Menu.Item>
                    <Menu.Item
                      key={'lineups'}
                    >
                      Lineups
                      {
                        this.props.opt.loading ? (
                          <Progress
                            style={{marginLeft: '5px'}}
                            percent={(Number(this.props.opt.data ? this.props.opt.data.length / 9 : 0)/Number(this.props.settings.data.numLUs)*100).toFixed(0)}
                            steps={5}
                            size="small"
                            strokeColor="#1890ff"
                            showInfo={false}
                          />
                        ) : ''
                      }
                    </Menu.Item>
                    {
                      this.props.sport == 'nfl' ? (
                        <Menu.Item 
                          key={'after-start'}
                          disabled={this.props.showdown || this.props.site !== 'dk' || this.props.subscription.loading || (this.props.subscription.permissions && !this.props.subscription.permissions.opt.nfl.after_start)}
                        >
                          After Start
                        </Menu.Item>
                      ) : ''
                    }
                    {
                      this.props.auth.admin ? (
                        <Menu.Item 
                          key={'admin'}
                        >
                          Admin
                        </Menu.Item>
                        ) : ''
                    } 
                  </> 
                )
              }
            </StyledMenu>
          </Col>
      </Row>
      <Row>
      </Row>
      <div>
        { body }
      </div>
  </div>
    )
  }
}

export default connect(
  state => ({
    savedLineups: state.lineup.savedLineups,
    loggedin: state.auth.loggedin,
    settings: state.lineup.settings,
    opt: state.lineup.opt,
    afterStartActivated: state.afterStart.activated,
    subscription: state.account.subscription,
    auth: state.auth,
    handbuildMode: state.lineup.handbuildMode,
    lineupRuns: state.lineup.lineupRuns
  }),
  {
    cancelOpt: lineupActions.cancelOpt,
    activateAfterStart: afterStartActions.activateAfterStart
  }
)(PositionalOptimizer)