import {
  Row,
  Col,
  Card,
  Alert
} from 'antd'
import {
  UploadOutlined,
  DownloadOutlined,
  CaretDownFilled,
  CaretUpFilled
} from '@ant-design/icons'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import {BrowserView, MobileView} from 'react-device-detect';

import { fuzzyObjSearch } from '../../../../utils/search'

import * as lineupActions from '../../../../actions/lineup'
import * as analysisActions from '../../../../actions/analysis'

import BasePlayerTable from './players-table'
import NBAPlayerTable from './nba/players-table'
import MLBPlayerTable from './mlb/players-table'
import NFLPlayerTable from './nfl/players-table'
import LastUpdated from '../../../../components/last-updated'

import { downloadCSV, writeRowCSVFromArray } from '../../../../utils/csv'

import PlayersTableFilterSection from '../../../../components/PlayersTableFilterSection'
import { getPlayerTablePositionsClassic, getPositionsShowdown, getPositionsClassic, getFlexPositionsForSport } from '../../../../utils/normalize-lineups'
import PlayerStatsModal from './modals/player-stats'
import AnalysisModal from './modals/analysis-modal'
import analysis from '../Lineups/analysis'
import { allowCheatsheets, partner } from '../../../../constants/partner'

import CheatsheetsSelectBar from '../../../../components/cheatsheet-select-bar'
import HandbuildBox from '../../../../components/handbuild-box'

import { getAvailablePositionsFromLineup, getCurrentLineup } from '../../../../utils/handbuilding'

const CSV_HEADINGS = ["Id","Name","Position","Team","ProjPts","ProjOwn","MinExp","MaxExp"]
const PROJ_CSV_HEADINGS = ["Id","Name","Position","Team","ProjPts","ProjOwn"]

const StyledSearch = styled.div`
  font-size: 18px;

  input {
    height: 40px;
  }

  .ant-btn {
    height: 40px;
  }
`
const HeadingCard = styled(Card)`
  background: #eaecef !important;
`
const ScrollableContainer = styled.div`
  width: 100%;
`

const getDefaultHidePPForSport = (sport) => {
  switch (sport) {
    case 'mlb':
      return true
    default:
      return true
  }
}

class Players extends PureComponent {
  state = {
    position: '',
    searchInput: '',
    hideZeroProjPts: getDefaultHidePPForSport(this.props.sport),
    baseExpVisible: false,
    hideZeroMaxExp: false,
    playerStatsVisible: false,
    selectedPlayer: {},
    cheatsheetID: null,
    showFiltersMobile: false,
    selectedTeam: '',
    analysisVisible: false,
    handbuildError: ''
  }

  static propTypes = {
    // Redux State goes here
    updatePlayersTable: PropTypes.func.isRequired
  }

  projPointsValid(rowData) {
    return ((rowData.UserProj ? Number(rowData.UserProj) >= 0 : true) && (rowData.UserOwn ? (Number(rowData.UserOwn) >= 0 && Number(rowData.UserOwn) <= 100) : true))
  }

  onProjectionChange(rowData) {
    const _players = this.props.players.data

    let isValid
    const players = _players.map(_player => {
      if (_player.Id === rowData.Id) {
        // if changes are not valid do not update
        isValid = this.projPointsValid(rowData)
        if (!isValid) return _player

        // return player with updated values
        return rowData
      }

      return _player
    })

    if (isValid)
      this.props.updatePlayersTable({
        payload: players,
        rowData,
        slate: this.props.slate,
        site: this.props.site,
        sport: this.props.sport,
        counter: this.props.counter,
        season: this.props.season
      })
  }

  // direction up or down
  onBoostChange(row, direction) {
    const _players = this.props.players.data
    const rowData = {...row}

    const players = _players.map(_player => {
      if (_player.Id === rowData.Id) {
        // if changes are not valid do not update
        // isValid = this.projPointsValid(rowData)
        let newBoost = Number(rowData.Boost || 0)
        if (direction === 'up') newBoost += 1
        else if (direction === 'down') newBoost -= 1
        
        if (newBoost > 5) newBoost = 5
        else if (newBoost < -5) newBoost = -5

        rowData.Boost = newBoost
        // return player with updated values
        return rowData
      }

      return _player
    })

    this.props.updatePlayersTable({
      payload: players,
      rowData,
      slate: this.props.slate,
      site: this.props.site,
      sport: this.props.sport,
      counter: this.props.counter,
      season: this.props.season
    })
  }

  async _uploadCustomInputs(_projections) {
    // Get Col Indexes
    const headers = _projections[0]
    let idIndex = headers.indexOf('Id')
    // We can't upload if no ID
    // TODO throw error to user
    if (idIndex === -1) {
      idIndex = headers.indexOf('ID')
      if (idIndex === -1) {
        console.error('No ID heading')
        return
      }
    }

    const projPtsIndex = headers.indexOf('ProjPts')
    const minExpIndex = headers.indexOf('MinExp')
    const maxExpIndex = headers.indexOf('MaxExp')
    const projOwnIndex = headers.indexOf('ProjOwn')
    // We must have something to change
    // TODO throw error to user
    if (projPtsIndex === -1 && minExpIndex === -1 && maxExpIndex === -1) {
      console.error('Could not find any expected headings')
      return
    }

    let playerMap = {}
    for (let i = 1; i < _projections.length ; i++) {
      const _pp = ((projPtsIndex > -1 && _projections[i][projPtsIndex] !== '' && typeof(_projections[i][projPtsIndex]) !== "undefined") ?
        Number(_projections[i][projPtsIndex]) :
        undefined
      )
      const _minExp = ((minExpIndex > -1 && _projections[i][minExpIndex] !== '' && typeof(_projections[i][minExpIndex]) !== "undefined") ?
        Number(_projections[i][minExpIndex]) :
        undefined
      )
      const _maxExp = ((maxExpIndex > -1 && _projections[i][maxExpIndex] !== '' && typeof(_projections[i][maxExpIndex]) !== "undefined") ?
        Number(_projections[i][maxExpIndex]) :
        undefined
      )
      const _po = ((projOwnIndex > -1 && _projections[i][projOwnIndex] !== '' && typeof(_projections[i][projOwnIndex]) !== "undefined") ?
        Number(_projections[i][projOwnIndex]) :
        undefined
      )

      // Don't add unless there is some change
      if (typeof(pp) !== "undefined" || typeof(_minExp) !== "undefined" || typeof(_maxExp) !== "undefined" || typeof(_po) !== "undefined") {
        playerMap[_projections[i][idIndex]] = {
          ProjPts: _pp,
          MinExp: _minExp,
          MaxExp: _maxExp,
          ProjOwn: _po
        }
      }
    }

    const rowData = []
    const players = this.props.players.data.map(_player => {
      if (playerMap[_player.Id]) {
        const _newPlayer = { ..._player }

        // Set proj pts
        if (typeof(playerMap[_player.Id].ProjPts) !== "undefined" && (String(playerMap[_player.Id].ProjPts) !== String(_newPlayer.ProjPts))) {
          // Keep track of the original Proj Pts
          _newPlayer.HouseProjPts = _newPlayer.HouseProjPts ? _newPlayer.HouseProjPts : _newPlayer.ProjPts

          _newPlayer.UserProj = playerMap[_player.Id].ProjPts
          _newPlayer.ProjPts = playerMap[_player.Id].ProjPts
        }
        // Set proj own
        if (typeof(playerMap[_player.Id].ProjOwn) !== "undefined" && (String(playerMap[_player.Id].ProjOwn) !== String(_newPlayer.ProjOwn))) {
          // Keep track of the original Proj Pts
          _newPlayer.HouseOwn = _newPlayer.HouseOwn ? _newPlayer.HouseOwn : _newPlayer.ProjOwn

          _newPlayer.UserOwn = playerMap[_player.Id].ProjOwn
          _newPlayer.ProjOwn = playerMap[_player.Id].ProjOwn
        }
        // Set min exp
        if (typeof(playerMap[_player.Id].MinExp) !== "undefined")
          _newPlayer.MinExp = playerMap[_player.Id].MinExp

        // Set min exp
        if (typeof(playerMap[_player.Id].MaxExp) !== "undefined")
          _newPlayer.MaxExp = playerMap[_player.Id].MaxExp

        // return player with updated values
        rowData.push(_newPlayer)
        return _newPlayer
      }

      return _player
    })

    this.props.updatePlayersTable({
      payload: players,
      rowData,
      slate: this.props.slate,
      site: this.props.site,
      sport: this.props.sport,
      season: this.props.season,
      counter: this.props.counter
    })
  }

  async downloadTemplate() {
    const players = this.props.players.data

    let csv = '';
    // Write headings
    csv = writeRowCSVFromArray(csv, CSV_HEADINGS)

    players.forEach(player => {
      csv = writeRowCSVFromArray(csv, [
        player.Id,
        player.Name,
        player.Position,
        player.TeamAbbrev,
        player.UserProj || '',
        player.UserOwn || '',
        player.MinExp || '',
        player.MaxExp || ''
      ])
    })

    downloadCSV(csv, `nfl-${this.props.site}-${this.props.slate}-player-inputs.csv`)
  }

  downloadProjections() {
    const players = this.props.players.data

    let csv = '';
    // Write headings
    csv = writeRowCSVFromArray(csv, PROJ_CSV_HEADINGS)

    players.forEach(player => {
      csv = writeRowCSVFromArray(csv, [
        player.Id,
        player.Name,
        player.Position,
        player.TeamAbbrev,
        Number(player.HouseProjPts || player.ProjPts).toFixed(2),
        Number(player.HouseProjOwn || player.ProjOwn || 0).toFixed(2),
      ])
    })

    downloadCSV(csv, `${partner}-${this.props.sport}-${this.props.site}-${this.props.slate}-projections.csv`)
  }

  _updatePosition(e) {
    const position = e.target.value
    if (position === 'ALL')
      this.setState({
        position: null
      })
    else
      this.setState({
        position
      })
  }

  _sortPlayersByPosition(_data) {
    const { position, hideZeroProjPts, hideZeroMaxExp } = this.state

    // Deep copy players
    let data = JSON.parse(JSON.stringify(_data))

    // First filter by cheatsheet players
    if (this.state.cheatsheetID) {
      const cheatsheet = this.props.admin.cheatsheets.filter(c => c.id === this.state.cheatsheetID)[0] || []
      data = data.filter(p => {
        return cheatsheet.player_picks.indexOf(p.Id) >= 0
      })
    }

    if (position === 'G') return data.filter(player => {
      let found = false
      player.Positions.forEach(pos => {
        if (['PG', 'SG'].indexOf(pos) >= 0)
          found = true
      })
      return found
    }) 
    
    if (position === 'F') return data.filter(player => {
      let found = false
      player.Positions.forEach(pos => {
        if (['PF', 'SF'].indexOf(pos) >= 0)
          found = true
      })
      return found
    }) 

    // Showdown
    if (this.props.showdown) {
      let _position = position
      if (!position)
        _position = 'FLEX'

      return data.filter(player => {
        if (hideZeroProjPts) {
          if (Number(player.ProjPts) === 0) {
            return false
          }
        }
        if (hideZeroMaxExp) {
          if (Number(player.MaxExp) === 0) {
            return false
          }
        }

        return player.RosterPosition === _position
      })
    }

    // Classic OPT
    if (!position) return data.filter(player => {
      if (hideZeroProjPts) {
        if (Number(player.ProjPts) === 0) {
          return false
        }
      }
      if (hideZeroMaxExp) {
        if (Number(player.MaxExp) === 0) {
          return false
        }
      }
      return true
    })

    if (position === 'FLEX' || position === 'UTIL') return data.filter(player => {
      let found = false
      player.Positions.forEach(pos => {
        if (getFlexPositionsForSport(this.props.sport).indexOf(pos) >= 0)
          found = true
      })
      return found
    })

    return data.filter(player => {
      let found = false
      player.Positions.forEach(pos => {
        if (pos === position)
          found = true
      })
      if (hideZeroProjPts) {
        if (Number(player.ProjPts) === 0) {
          found = false
        }
      }
      if (hideZeroMaxExp) {
        if (Number(player.MaxExp) === 0) {
          return false
        }
      }
      return found
    })
  }

  _sortPlayersByTeam = (data) => {
    if (!this.state.selectedTeam) return data
    return data.filter(p => {
      return p.TeamAbbrev === this.state.selectedTeam
    })
  }

  _search(value) {
    this.setState({
      searchInput: value
    })
  }

  _filterDataForSearchTerm = (data, searchTerm) => {
    return data.filter(obj => fuzzyObjSearch(searchTerm, obj))
  }

  _selectPlayerStats(player) {
    // this.props.fetchPlayerStats({ name: player.Name, position: player.Position })
    // this.setState({playerStatsVisible: true, selectedPlayer: player})
  }

  filterOnCheatsheet(cheatsheetID) {
    this.setState({
      cheatsheetID
    }) 
  }

  setSelectedTeam(team) {
    this.setState({
      selectedTeam: team
    })
  }

  setAnalysisVisible(visible) {
    this.setState({
      analysisVisible: visible
    })
  }

  activateHandbuildMode(active) {
    this.props.setHandbuildMode({active})
  }

  removePlayerFromHandbuild(player) {
    const _p = {...player}
    _p.MinExp = 0
    this.props.onExposureChange(_p)
  }

  resetHandbuild(currentLineup) {
    const resetPlayers = []
    Object.keys(currentLineup).forEach(pos => {
      if (currentLineup[pos]) {
        resetPlayers.push({...currentLineup[pos], MinExp: 0})
      }
    })

    this.props.onExposureChange(resetPlayers)
  }

  isPlayerLockValid(newPlayer) {
    const playersCopy = JSON.parse(JSON.stringify(this.props.players.data))
    const _newPlayer = JSON.parse(JSON.stringify(newPlayer))
    _newPlayer.MinExp = 100
    _newPlayer.MaxExp = 100
    let idx = -1
    playersCopy.forEach((p, i) => {
      if (p.Id === _newPlayer.Id) {
        idx = i
      }
    })

    if (idx >= 0) {
      playersCopy[idx] = _newPlayer
    } else {
      return false
    }

    const [lineup, errors] = getCurrentLineup(
      playersCopy, 
      this.props.showdown ? getPositionsShowdown(this.props.site, this.props.sport) : getPositionsClassic(this.props.site, this.props.sport), 
      this.props.showdown,
      this.props.sport,
      this.props.site
    )

    if (errors.length) return false
    return true
  }

  getAvailalbePositionsForHandbuild() {
    const [lineup, errors] = getCurrentLineup(
      this.props.players.data, 
      this.props.showdown ? getPositionsShowdown(this.props.site, this.props.sport) : getPositionsClassic(this.props.site, this.props.sport),
      this.props.showdown,
      this.props.sport,
      this.props.site
    )

    return getAvailablePositionsFromLineup(lineup, this.props.sport)
  }

  setHandbuildErrors(e) {
    this.setState({
      handbuildError: e
    })

    // make error disapear in 2 secs
    setTimeout(() => {
      this.setState({
        handbuildError: ''
      })
    }, 2000)
  }

  render() {
    const {
      players
    } = this.props

    const { searchInput } = this.state

    let PlayersTable
    switch (this.props.sport) {
      case 'nfl':
        PlayersTable = NFLPlayerTable
        break
      case 'mlb':
        PlayersTable = MLBPlayerTable
        break
      case 'nba':
        PlayersTable = NBAPlayerTable
        break
      default:
        PlayersTable = BasePlayerTable
        break
    }

    let data = players.data ? this._sortPlayersByPosition(players.data) : []
    data = this._sortPlayersByTeam(data)
    if (searchInput)
      data = this._filterDataForSearchTerm(data, searchInput)


    const FiltersSection = (
      <PlayersTableFilterSection 
        showdown={this.props.showdown}
        site={this.props.site}
        sport={this.props.sport}
        slate={this.props.slate}
        positions={this.props.showdown ? getPositionsShowdown(this.props.site, this.props.sport) : getPlayerTablePositionsClassic(this.props.site, this.props.sport)}
        updatePosition={this._updatePosition.bind(this)}
        downloadTemplate={this.downloadTemplate.bind(this)}
        uploadCustomInputs={this._uploadCustomInputs.bind(this)}
        clearPlayerData={this.props.clearPlayerData}
        zeroExposures={this.props.zeroExposures}
        selectedPosition={this.state.position}
        search={this._search.bind(this)}
        players={this.props.players.data}
        onExposureChange={this.props.onExposureChange}
        baseExpVisible={this.state.baseExpVisible}
        hideZeroMaxExp={this.state.hideZeroMaxExp}
        hideZeroProjPts={this.state.hideZeroProjPts}
        setBaseExpVisible={(k) => {this.setState({
          baseExpVisible: k
        })}}
        setHideZeroMaxExp={(k) => {this.setState({
          hideZeroMaxExp: k
        })}}
        setHideZeroProjPts={(k) => {this.setState({
          hideZeroProjPts: k
        })}}
        selectedTeam={this.state.selectedTeam}
        setSelectedTeam={this.setSelectedTeam.bind(this)}
        setAnalysisVisible={this.setAnalysisVisible.bind(this)}
        handbuildMode={this.props.handbuildMode.active}
        setHandbuildMode={this.activateHandbuildMode.bind(this)}
        downloadProjections={this.downloadProjections.bind(this)}
      />
    )

    return (
      <div>
        <Card>
          <BrowserView>
            { FiltersSection }
          </BrowserView>
          <MobileView>
            {
              this.state.showFiltersMobile ? (
                <div>
                  <Row>
                      <Col 
                        sm={8} 
                        xs={8} 
                        style={{cursor: 'pointer', fontWeight: 600}}
                        onClick={() => {
                          this.setState({
                            showFiltersMobile: false
                          })
                        }}
                      >
                        Hide Filters <CaretUpFilled />
                      </Col>
                    </Row>
                    { FiltersSection }
                </div>
              ) : (
                <Row>
                  <Col 
                    sm={8} 
                    xs={8} 
                    style={{cursor: 'pointer', fontWeight: 600}}
                    onClick={() => {
                      this.setState({
                        showFiltersMobile: true
                      })
                    }}
                  >
                    Show Filters <CaretDownFilled />
                  </Col>
                </Row>
              )}
          </MobileView>
        </Card>
        {
          !this.props.handbuildMode.active && this.state.handbuildError ? (
            <Alert message={this.state.handbuildError} type="error" />
          ) : ''
        }
        <Row>
          <Col lg={24} md={24}>
            { 
              allowCheatsheets ? (
                <CheatsheetsSelectBar userCheatsheets={this.props.admin.cheatsheets} onCheatsheetFilter={this.filterOnCheatsheet.bind(this)} selectedCheatsheet={this.state.cheatsheetID} loading={this.props.admin.cheatsheetsLoading}/>
              ) : ''
            }
          </Col>
        </Row>
        <Row>
          <Col lg={24}>
            <LastUpdated timestamp={players ? players.updatedAt : null} refresh={() => {
              this.props.fetchPlayers(
                {
                  slate: this.props.slate, 
                  site: this.props.site, 
                  sport: this.props.sport, 
                  showdown: this.props.showdown,
                  counter: this.props.counter,
                  season: this.props.season
                }
              )}}/>
          </Col>
        </Row>
        {
          this.props.subscription.permissions && !this.props.subscription.permissions.opt.nfl.projections ? (
            <Alert 
              message={(
                <span>You do not have access to the optimizer. See <a href={'https://ftnfantasy.com/pricing'}>Plans</a> to upgrade to a subscription with DFS access.</span>
              )}
              type="error"
              style={{textAlign: 'center', marginBottom: '10px'}}
            ></Alert>
          ) : ''
        }
        <ScrollableContainer>
            {
              this.props.handbuildMode.active ? (
                <Row>
                  <Col lg={17} md={24} sm={24} xs={24}>
                    <PlayersTable
                      loading={players.loading}
                      rows={data || []}
                      expOnChange={this.props.onExposureChange}
                      userProjOnChange={this.onProjectionChange.bind(this)}
                      permissions={this.props.subscription.permissions}
                      site={this.props.site}
                      showdown={this.props.showdown}
                      selectPlayerStats={this._selectPlayerStats.bind(this)}
                      onBoostChange={this.onBoostChange.bind(this)}
                      cheatsheetPlayers={this.props.admin.cheatsheets || []}
                      sport={this.props.sport}
                      handbuildMode={true}
                      availablePositions={this.getAvailalbePositionsForHandbuild()}
                      setHandbuildError={this.setHandbuildErrors.bind(this)}
                      showVegas={this.props.sport === 'nfl' ? true : false}
                      isPlayerLockValid={this.isPlayerLockValid.bind(this)}
                      hasGPPScore={this.props.sport === 'nfl' || this.props.sport === 'mlb'}
                    />
                  </Col>
                  <Col lg={7} md={24} sm={24} xs={24} style={{padding: '0px 0px 0px 5px'}}>
                    <HandbuildBox
                      players={this.props.players.data}
                      site={this.props.site}
                      slate={this.props.slate}
                      removePlayer={this.removePlayerFromHandbuild.bind(this)}
                      clearAll={this.resetHandbuild.bind(this)}
                      handbuildError={this.state.handbuildError}
                      showdown={this.props.showdown}
                      maxSalary={this.props.settings.data.maxSalDefault}
                      sport={this.props.sport}
                    />
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col lg={24}>
                    <PlayersTable
                      loading={players.loading}
                      rows={data || []}
                      expOnChange={this.props.onExposureChange}
                      userProjOnChange={this.onProjectionChange.bind(this)}
                      permissions={this.props.subscription.permissions}
                      site={this.props.site}
                      showdown={this.props.showdown}
                      selectPlayerStats={this._selectPlayerStats.bind(this)}
                      onBoostChange={this.onBoostChange.bind(this)}
                      cheatsheetPlayers={this.props.admin.cheatsheets || []}
                      sport={this.props.sport}
                      handbuildMode={false}
                      availablePositions={this.getAvailalbePositionsForHandbuild()}
                      setHandbuildError={this.setHandbuildErrors.bind(this)}
                      showVegas={this.props.sport === 'nfl' ? true : false}
                      isPlayerLockValid={this.isPlayerLockValid.bind(this)}
                      hasGPPScore={this.props.sport === 'nfl' || this.props.sport === 'mlb'}
                    />
                  </Col>
                </Row>
              )
            }
        </ScrollableContainer>
        <PlayerStatsModal 
          loading={this.props.analysis.playerStats.loading}
          visible={this.state.playerStatsVisible}
          setVisible={(v) => {this.setState({playerStatsVisible: v})}}
          playerStats={this.props.analysis.playerStats.data}
          player={this.state.selectedPlayer}
          site={this.props.site}
          sport={this.props.sport}
        />
        <AnalysisModal
          players={this.props.players.data}
          onExposureChange={this.props.onExposureChange}
          settings={this.props.settings.data}
          teamStacks={this.props.teamStacks.data}
          permissions={this.props.subscription.permissions}
          positionCounts={this.props.positionCounts.data}
          showdown={this.props.showdown}
          site={this.props.site}
          counter={this.props.counter}
          season={this.props.season}
          sport={this.props.sport}
          visible={this.state.analysisVisible}
          setVisible={this.setAnalysisVisible.bind(this)}
        />
      </div>
    )
  }
}

export default connect(
  state => ({
    players: state.lineup.players,
    loggedin: state.auth.loggedin,
    analysis: state.analysis,
    subscription: state.account.subscription,
    playerStats: state.analysis.playerStats,
    teamStacks: state.lineup.teamStacks,
    settings: state.lineup.settings,
    positionCounts: state.analysis.positionCounts,
    admin: state.admin,
    handbuildMode: state.lineup.handbuildMode
  }),
  {
    updatePlayersTable: lineupActions.updatePlayersTable,
    fetchPlayers: lineupActions.fetchPlayers,
    fetchPlayerStats: analysisActions.fetchPlayerStats,
    setHandbuildMode: lineupActions.setHandbuildMode
  }
)(Players)
